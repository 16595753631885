import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist';


class CustomerStore {
    customerFirstName = '';
    customerLastName = '';
    customerEmail = '';
    customerCountryCode = '';
    customerPhoneNo = '';
    customerSecondaryPhoneNo = '';
    companyName = '';
    userAgeConsent = '';
    userConsent = '';
    isValidatedForm = false;
    street1 = '';
    street2 = '';
    city = '';
    zipCode = '';

    updateCustomerDetail = (customerDetail) => {
        this.customerFirstName = customerDetail.customerFirstName;
        this.customerLastName = customerDetail.customerLastName;
        this.customerEmail = customerDetail.customerEmail;
        this.customerCountryCode = customerDetail.customerCountryCode;
        this.customerPhoneNo = customerDetail.customerPhoneNo;
        this.userAgeConsent = customerDetail.userAgeConsent;
        this.userConsent = customerDetail.userConsent;
        this.isValidatedForm = customerDetail.isValidatedForm;
        this.customerSecondaryPhoneNo = customerDetail.customerSecondaryPhoneNo;
        this.companyName = customerDetail.companyName;
        this.street1 = customerDetail.street1;
        this.street2 = customerDetail.street2;
        this.city = customerDetail.city;
        this.zipCode = customerDetail.zipCode;
    }

    clear = () => {
        this.customerFirstName = '';
        this.customerLastName = '';
        this.customerEmail = '';
        this.customerCountryCode = '';
        this.customerPhoneNo = '';
        this.customerSecondaryPhoneNo = '';
        this.companyName = '';
        this.userAgeConsent = '';
        this.userConsent = '';
        this.isValidatedForm = false;
        this.street1 = '';
        this.street2 = '';
        this.city = '';
        this.zipCode = '';
    }
}

decorate(CustomerStore, {
    customerFirstName: [persist, observable],
    customerLastName: [persist, observable],
    customerEmail: [persist, observable],
    customerCountryCode: [persist, observable],
    customerPhoneNo: [persist, observable],
    userAgeConsent: [persist, observable],
    userConsent: [persist, observable],
    isValidatedForm: [persist, observable],
    customerSecondaryPhoneNo: [persist, observable],
    companyName: [persist, observable],
    street1: [persist, observable],
    street2: [persist, observable],
    city: [persist, observable],
    zipCode: [persist, observable],
    clear: action,
    updateCustomerDetail: action
})
var customerStore = new CustomerStore();
export default customerStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('customerStore', customerStore);
}
