import React, { useEffect, useState } from "react"
import Circle from "../../assets/images/progress_node.svg"
import Check from "../../assets/images/progress_done.svg"
import CircleO from "../../assets/images/progress_initial.svg"
import translate from '../../i18n/translate'

const ProgressIndicatorComponent = props => {
  const [completed, setcompleted] = useState(0);
  const [currentStep, setcurrentStep] = useState(0);

  let isMobile;
  if (typeof window !== 'undefined'){
    isMobile = window.innerWidth <= 768;
  }

  const [steps] = useState([
    {
      label: translate("progress.item1"),
      route: "device-info",
      progress: 0.2,
    },
    {
      label:  translate("progress.item2"),
      route: "contact-info",
      progress: 0.65,
    },
    {
      label: translate("progress.item3"),
      route: "order-summary",
      progress: 1,
    }
  ]);

  useEffect(() => {
    var path = props.route;
    steps.map((step, index) => {
      if (step.route === path) {
        setcompleted(step.progress);
        setcurrentStep(index);
      }
    })
  }, [props.route, steps])

  return (
    <div className="progress-wrapper">
      <div className="step-wrapper">
        {steps.map((step, index) => {
          return (
            <div className="step" key={step.route}>
              <div>
                {currentStep === index ? (
                  <img className="marker" src={Circle} alt="circle" />
                ) : null}
                {currentStep > index ? (
                  <img className="marker" src={Check} alt="check" />
                ) : currentStep !== index ? (
                  <img className="marker" src={CircleO} alt="circle" />
                ) : null}
              </div>
              <div
                className={
                  currentStep === index ? `step-label active` : `step-label`
                }
              >
                {step.label}
              </div>
            </div>
          )
        })}
      </div>

      <div className="progress-bar-wrapper">
        <div
          className="progress-bar"
          style={
            isMobile
              ? {
                  width: `${completed * 100}%`,
                }
              : { height: `${completed * 100}%` }
          }
        ></div>
      </div>

      <div className="step-label-mobile active">
        {currentStep <= steps.length - 1 ? steps[currentStep]?.label : null}
      </div>
    </div>
  )
}

export default ProgressIndicatorComponent
